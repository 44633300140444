export const menuItemsCDN = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    // {
    //     id: 2,
    //     label: "Tableau de bord",
    //     icon: "bx-home-circle",
    //     link: "#"
    // },
    {
        id: 9,
        label: "Secteurs",
        link: "/cdn/sectors",
        icon: "bx-calendar",
    },
    {
        id: 10,
        label: "Parténaires",
        icon: "bx-chat",
        link: "/cdn/patners",
    },
    {
        id: 11,
        label: "Projets",
        link: "/cdn/projects",
        icon: "bx-file",
    },
    {
        id: 10,
        label: "Evènements",
        icon: "bx-calendar",
        link: "/cdn/events",
    },
    {
        id: 10,
        label: "Sondages",
        icon: "bx-filter-alt",
        link: "/cdn/surveys",
    },
    {
        id: 10,
        label: "Formulaires",
        icon: "bx-notepad",
        link: "/cdn/formulars",
    },
    {
        id: 10,
        label: "Courriers",
        icon: "bx-envelope",
        link: "/cdn/courriers",
    },
    {
        id: 10,
        label: "Discussions",
        icon: "bx-chat",
        link: "/chat",
    },
    {
        id: 12,
        label: "Divers",
        icon: "bx-layout",
        subItems: [{
            id: 13,
            label: "Galerie",
            link: "/cdn/gallery",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport annuel",
            link: "/cdn/annual-reports",
            parentId: 12
        },  {
            id: 13,
            label: "Rapport trimestriel",
            link: "/cdn/trimester-reports",
            parentId: 12
        }, ]
    },
    {
        id: 12,
        label: "Définition",
        icon: "bx-mouse-alt",
        subItems: [{
            id: 13,
            label: "Domaines",
            link: "/cdn/categories",
            parentId: 12
        }, {
            id: 13,
            label: "Organes",
            link: "/cdn/organes",
            parentId: 12
        }, {
            id: 13,
            label: "Utilisateurs",
            link: "/cdn/users",
            parentId: 12
        }, ]
    },
];


export const menuItemsSector = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    // {
    //     id: 2,
    //     label: "Tableau de bord",
    //     icon: "bx-home-circle",
    //     link: "#"
    // },
    {
        id: 11,
        label: "Projets",
        link: "/sector/projects",
        icon: "bx-file",
    },
    {
        id: 10,
        label: "Evènements",
        icon: "bx-calendar",
        link: "/events",
    },
    {
        id: 10,
        label: "Sondages",
        icon: "bx-filter-alt",
        link: "/surveys",
    },
    {
        id: 10,
        label: "Formulaires",
        icon: "bx-notepad",
        link: "/formulars",
    },
    {
        id: 10,
        label: "Courriers",
        icon: "bx-envelope",
        link: "/courriers",
    },
    {
        id: 10,
        label: "Discussions",
        icon: "bx-chat",
        link: "/chat",
    },
    {
        id: 12,
        label: "Divers",
        icon: "bx-layout",
        subItems: [{
            id: 13,
            label: "Galerie",
            link: "/sector/gallery",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport annuel",
            link: "/sector/annual-reports",
            parentId: 12
        }, {
            id: 13,
            label: "Rapport trimestriel",
            link: "/cdn/trimester-reports",
            parentId: 12
        },]
    },
];

export const menuItemsPatner = [{
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    // {
    //     id: 2,
    //     label: "Tableau de bord",
    //     icon: "bx-home-circle",
    //     link: "#"
    // },
    {
        id: 11,
        label: "Projets",
        link: "/patner/projects",
        icon: "bx-file",
    },
    {
        id: 10,
        label: "Evènements",
        icon: "bx-calendar",
        link: "/events",
    },
    {
        id: 10,
        label: "Sondages",
        icon: "bx-filter-alt",
        link: "/surveys",
    },
    {
        id: 10,
        label: "Formulaires",
        icon: "bx-notepad",
        link: "/formulars",
    },
    {
        id: 10,
        label: "Courriers",
        icon: "bx-envelope",
        link: "/courriers",
    },
    {
        id: 10,
        label: "Discussions",
        icon: "bx-chat",
        link: "/chat",
    },
];

// export const menuItems = [{
//         id: 1,
//         label: "menuitems.menu.text",
//         isTitle: true
//     },
//     {
//         id: 2,
//         label: "menuitems.dashboards.text",
//         icon: "bx-home-circle",
//         badge: {
//             variant: "info",
//             text: "menuitems.dashboards.badge"
//         },
//         subItems: [{
//                 id: 3,
//                 label: "menuitems.dashboards.list.default",
//                 link: "/",
//                 parentId: 2
//             },
//             {
//                 id: 4,
//                 label: "menuitems.dashboards.list.saas",
//                 link: "#",
//                 parentId: 2
//             },
//             {
//                 id: 5,
//                 label: "menuitems.dashboards.list.crypto",
//                 link: "#",
//                 parentId: 2
//             },
//             {
//                 id: 6,
//                 label: "menuitems.dashboards.list.blog",
//                 link: "#",
//                 parentId: 2
//             }
//         ]
//     },
//     {
//         id: 7,
//         isLayout: true
//     },
//     {
//         id: 8,
//         label: "menuitems.apps.text",
//         isTitle: true
//     },
//     {
//         id: 9,
//         label: "menuitems.calendar.text",
//         icon: "bx-calendar",
//         link: "#"
//     },
//     {
//         id: 10,
//         label: "menuitems.chat.text",
//         icon: "bx-chat",
//         link: "#",
//     },
//     {
//         id: 11,
//         label: "menuitems.filemanager.text",
//         link: "#",
//         icon: "bx-file",
//         badge: {
//             variant: "success",
//             text: "menuitems.chat.badge"
//         }
//     },
//     {
//         id: 12,
//         label: "menuitems.ecommerce.text",
//         icon: "bx-store",
//         subItems: [{
//             id: 13,
//             label: "menuitems.ecommerce.list.products",
//             link: "#",
//             parentId: 12
//         }, ]
//     },
// ];